<template>
  <div class="trade-info container">
    <Serach placeholder="请输入项目关键词进行搜索" :serchValue="serchValue" style="height: 64px;" @serch="serch"></Serach>
    <FilterCondition @getList=getList></FilterCondition>
    <fastList v-if="form.isGov == '3'" v-loading="loading" :propList=fastList />
    <InfoList v-else v-loading="loading" :propList=propList></InfoList>
    <el-pagination
      v-if="total > 10"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="currentChange"
      :page-sizes="[5, 10, 20]"
      layout="prev, pager, next"
      background
      prev-text="上一页"
      next-text="下一页"
    ></el-pagination>
    <p class="nomore" v-show="total < 10 && total > 0">没有了~</p>
  </div>
</template>

<script>
import Serach from '../Layout/SearchInput.vue'
import FilterCondition from "@/components/TradeInfo/FilterCondition.vue";
import InfoList from "@/components/TradeInfo/components/InfoList.vue"
import tradeInfoApi from "@/api/tradeInfo";
import indexApi from "@/api/index";
import fastList from "@/components/TradeInfo/components/fastList.vue"
export default {
  components: {
    Serach, FilterCondition, InfoList, fastList
  },
  data() {
    return {
      loading: false,
      serchValue: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      propList: [],
      fastList: [],
      form: {
        isGov: "",
        type: "",
      },
      searchStr: ''
    }
  },
  created() {
    if (this.$route.query.serch) {
      this.serchValue = this.$route.query.serch
    }
  },
  mounted() {
   
  },
  methods: {
    getList(data) {
      this.currentPage = 1; //不管是isGov改变还是type改变，都要从第一页开始查
      this.form = {...data};
      this.renderList();
    },
    serch(val) {
      this.currentPage = 1;
      this.serchValue = val
      this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          serch: val
        }
      })
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    async renderList() {
      try {
        this.loading = true
        if (this.form.isGov == '3') {
          let apiFc = 'getAllFast'
          if (this.form.type === '全部') {
            apiFc = 'getAllFast'
          } else if (this.form.type === '招标公告') {
            apiFc = 'getFastNotice'
          } else if (this.form.type === '结果公示') {
            apiFc = 'getFastPublicity'
          }
          let {data} = await indexApi[apiFc]({
            page: this.currentPage,
            size: this.pageSize,
            keyword: this.serchValue
          })
          this.fastList = data.records;
          this.total = data.total;
        } else {
          let { code, data, message } = await tradeInfoApi.findTradeList({
            isGov: this.form.isGov,
            type: this.form.type,
            page: this.currentPage,
            size: this.pageSize,
            sort: "desc",
            sortFiled: "release_time",
            title: this.serchValue
          });
          this.propList = data.records;
          this.propList.forEach(v => {
            if (v.type == 5) {
              v.title = `[修正] ${v.title}`;
            }
          });
          this.total = data.total;
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .trade-info {
    margin-top: 16px;
    margin-bottom: 32px;
    .nomore {
      text-align: center;
      font-size: 14px;
      color: #C3C3C3;
    }
  }
</style>