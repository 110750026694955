<template>
  <div class="trade-info">
    <!-- <Banner></Banner> -->
    <index></index>
    <!-- <filter-condition></filter-condition> -->
    <!-- <List></List> -->
  </div>
</template>

<script>
// import Banner from "@/components/TradeInfo/Banner.vue";
// import FilterCondition from "@/components/TradeInfo/FilterCondition.vue";
import index from "@/components/TradeInfo/index.vue";

export default {
  components: { index }
};
</script>

<style lang="less" scoped>
.trade-info {
}
</style>