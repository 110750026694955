<template>
  <div class="filter-condition container">
    <el-form :model="form" size="medium">
      <el-form-item label="项目类别">
        <el-radio-group v-model="form.isGov" @change="isGovChange">
          <el-radio-button label="1">工程建设</el-radio-button>
          <el-radio-button label="2">采购</el-radio-button>
          <el-radio-button label="3">快速招采</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="信息类型">
        <el-radio-group v-model="form.type" @change="typeChange">
          <template v-if="form.isGov==1">
            <el-radio-button v-for="v in engNavList" :key="v.id" :label="v.moduleName"></el-radio-button>
          </template>
          <template v-if="form.isGov==2">
            <el-radio-button v-for="v in govNavList" :key="v.id" :label="v.moduleName"></el-radio-button>
          </template>
          <template v-if="form.isGov==3">
            <el-radio-button v-for="(v) in fastNavList" :key="v" :label="v"></el-radio-button>
          </template>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";

export default {
  async created() {
  },
  async mounted() {
    this.form = JSON.parse(JSON.stringify(this.$route.query));
    let { code, data, message } = await tradeInfoApi.findTradeNav();
    this.engNavList = data[0].children;
    this.govNavList = data[1].children;
    this.$emit('getList', this.form)
    // 获取招采信息导航
    

    // 获取到导航之后才去回填筛选条件

    // 用回填好的筛选条件去请求list并响应
   
  },
  beforeDestroy() {
    // this.$bus.$off("search");
  },
  data() {
    return {
      engNavList: [],
      govNavList: [],
      fastNavList: [
        '全部',
        '招标公告',
        '结果公示'
      ],
      form: {
        isGov: "1",
        type: "全部",
      }
    };
  },
  methods: {
    isGovChange(isGov) {
      this.form = {
        isGov,
        type: '全部'
      }
      this.$emit('getList', {...this.form})
      // this.$router.push({
      //   query: {
      //     isGov: isGov,
      //     type: '全部'
      //   }
      // })
    },
    typeChange(val) {
      this.form = {
        isGov: this.form.isGov,
        type: val
      }
      // this.$router.push({
      //   query: {
      //     type: val,
      //     isGov: this.form.isGov
      //   }
      // })
    this.$emit('getList', this.form)
    }
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      if (newVal.n) {
        this.form = newVal;
        this.$emit('getList', this.form)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.filter-condition {
  margin-bottom: 20px;
  margin-top: 16px;
  min-height: 140px;
  background: linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%);
  box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
  border: 2px solid #FFFFFF;
  padding: 20px 16px;
  padding-bottom: 0px;
  .el-form {
    .el-form-item {
      display: flex;
      margin-bottom: 8px;
      /deep/.el-form-item__label {
        font-size: 18px;
        font-weight: bold;
        color: #20201E;
      }
      /deep/.el-form-item__content {
        flex: 1;
        .el-radio-group {
          display: flex;
          flex-wrap: wrap;
        }
        .el-radio-button {
          margin-right: 14px;
          margin-bottom: 12px;
          .el-radio-button__inner {
            border: 1px solid #fff;
            background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
            border-radius: 4px 4px 4px 4px;
            box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
            height: 40px;
            line-height: 38px;
            font-size: 16px;
            color: #20201E;
            font-weight: 400;
            width: 124px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s, color 0s;
            &:hover {
              border: 1px solid #F36E31;
              color: #fff;
              background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
              box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3);
            }
          }
          .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border: 1px solid #F36E31;
            color: #fff;
            background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
            box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3) !important;
          }
        }
      }
    }
  }
}
</style>